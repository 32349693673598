import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 全局引入按需引入UI库 vant
import '@/plugins/vant'
import 'vant/lib/index.css'

import anchorFunc from './utils/anchor'
import '../.postcssrc.js';
import "@/utils/flexible";
// import i18n from './i18n/i18n'
import localStorage from '@/utils/localStorage.js';
Vue.prototype.$local = localStorage;  //其中$local为新命的名。
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.config.productionTip = false;
Vue.directive('anchor', anchorFunc);


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // console.log(to);
  // if (to.meta.title) {
  //   if (to.name == "List") {
  //     document.title = to.params.name + ' | Webnovelsite'
  //   } else if (to.name == 'Genre') {
  //     document.title = to.query.type + ' | Webnovelsite'
  //   } else if (to.name == 'ArticleList') {
  //     document.title = to.query.name + ' | Webnovelsite'
  //   } else if (to.name == 'Article') {
  //     document.title = to.query.name + '-' + to.query.cName + ' | Webnovelsite'
  //   } else {
  //     document.title = to.meta.title
  //   }
  // }
  // 进入页面滚动条回到顶部
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0// firefox浏览器
  next()
})

new Vue({
  router,
  store,
  // i18n,
  render: (h) => h(App),
  //这里与vue.config.js中的事件名相对应
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
