import storageProxy from 'zp-lib/src/storage/proxy';

const STORAGE_KEY = 'police';

const storage = storageProxy(STORAGE_KEY);

const getBookInfo = () => storage.bookInfo;

function removeBookInfo() {
  delete storage.bookInfo;
}

function removeSave(name) {
}

export default storage;
export {
  getBookInfo,
  removeBookInfo,
  removeSave,
};
