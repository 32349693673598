import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const path = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
  ? "M"
  : "PC";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: (resolve) => {
      require([`@/views/${path}/home.vue`], resolve);
    },

  },
  {
    path: "/search",
    name: "Search",
    component: (resolve) => {
      require([`@/views/${path}/list.vue`], resolve);
    },
  },
  {
    path: "/genre/:id",
    name: "Genre",
    component: (resolve) => {
      require([`@/views/${path}/list.vue`], resolve);
    },
  },
  {
    path: "/list/:name",
    name: "List",
    component: (resolve) => {
      require([`@/views/${path}/list.vue`], resolve);
    },
  },
  {
    path: "/author/:name",
    name: "Author",
    component: (resolve) => {
      require([`@/views/${path}/list.vue`], resolve);
    },
  },
  {
    path: "/status/:key",
    name: "Status",
    component: (resolve) => {
      require([`@/views/${path}/list.vue`], resolve);
    },
  },
  {
    path: "/article/:nid/:cid",
    name: "Article",
    component: (resolve) => {
      require([`@/views/${path}/article.vue`], resolve);
    },
  },
  {
    path: "/articleList/:id",
    name: "ArticleList",
    component: (resolve) => {
      require([`@/views/${path}/articleList.vue`], resolve);
    },
  },
  // {
  //   path: "/agreement",
  //   name: "Agreement",
  //   component: (resolve) => {
  //     require([`@/views/${path}/agreement.vue`], resolve);
  //   },
  // },
  {
    path: "/contactUs",
    name: "ContactUs",
    component: (resolve) => {
      require([`@/views/${path}/contactUs.vue`], resolve);
    },
  },
  {
    path: "/404",
    name: "404",
    component: (resolve) => {
      require([`@/views/${path}/404.vue`], resolve);
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];
// 防止连续点击多次路由报错
// let routerPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
  mode: "history", //hash、history
  base: process.env.BASE_URL,
  // '/freenovel/'
  routes,
});

export default router;
