
export default {

    inserted: function (el, binding) {
        // 是否为手机端
        const flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        const ua = window.navigator.userAgent.toLowerCase();
        const environmental = ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false
        let height
        flag ? height = 68 : height = 100
        el.onclick = function () {

            let total;
            if (binding.value == 0) {
                total = 0;
            } else {
                total = document.getElementById(`anchor-${binding.value}`).offsetTop;
                total -= height
            }
            let distance = document.documentElement.scrollTop || document.body.scrollTop;
            distance -= height
            let step = total / 50;
            if (total > distance) {

                (function smoothDown() {
                    if (distance < total) {
                        distance += step;
                       environmental && flag?document.body.scrollTop = distance:document.documentElement.scrollTop = distance
                        setTimeout(smoothDown, 5);
                    } else {
                        environmental && flag?document.body.scrollTop = total:document.documentElement.scrollTop = total
                    }
                })();
            } else {

                let newTotal = distance - total;
                step = newTotal / 50;
                (function smoothUp() {
                    if (distance > total) {
                        distance -= step;
                        environmental && flag?document.body.scrollTop = distance:document.documentElement.scrollTop = distance
                        setTimeout(smoothUp, 5);
                    } else {
                        environmental && flag?document.body.scrollTop = total:document.documentElement.scrollTop = total
                    }
                })();
            }

        }
    }
}