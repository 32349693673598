import Vue from 'vue'
import Vuex from 'vuex'
import storage, { removeBookInfo } from './auth';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        bookInfo: storage.bookInfo || {},
    },
    mutations: {
        set_bookInfo(state, data) {
            state.bookInfo = data;
            storage.bookInfo = data;
        },
    },
    getters: {
        bookInfo: (state) => state.bookInfo,
    },
    actions: {
    },
    modules: {
    }
})
