// // 存数据
// cacheSet('key', '123', 1000); // 1000秒后过期

// // 取数据
// cacheGet('key')


/**
 * 设置 localStoryage
 * @param key 键
 * @param value 值
 * @param expire 过期时间(秒) 缓存最大时间请勿超过 forceTime 否则每次都会清空数据 (未设置请忽略)
 * @return bool true
 */
export function cacheSet(key, value, expire) {

    // 当前时间戳
    var nowTime = Math.ceil(Date.now() / 1000);
    // 设置数据
    localStorage.setItem(key, JSON.stringify(value))
    // 判断是否有过期时间
    if (expire > 0) {
        // 设置过期时间
        localStorage.setItem(key + '_expire', (nowTime + parseInt(expire)))
        console.log(localStorage.getItem(key + '_expire'), "设置过期时间");
    } else {
        // 设置过期时间
        localStorage.setItem(key + '_expire', 0)
    }
    return true;
}

/**
 * 读取 localStoryage
 * @param key
 * @return bool|originData false|originData
 */
export function cacheGet(key) {
    // 当前时间戳
    var nowTime = Math.ceil(Date.now() / 1000);
    // 获取键时间戳
    var rawCacheDataExpire = localStorage.getItem(key + '_expire');
    var cacheDataExpire = parseInt(rawCacheDataExpire);
    // 强制过期时间 为0时忽略 用于解决缓存时间与本地时间差距过大(例本地更改了计算机时间)
    var forceTime = 24 * 60 * 60;
    // 判断用户是否删除了过期时间 判断是否设置了过期时间 判断是否超过过期时间 判断当前计算机时间与设置的过期时间差距是否过大
    if ((rawCacheDataExpire === null) || (cacheDataExpire > 0) && ((nowTime > cacheDataExpire) || (forceTime > 0 && Math.abs(cacheDataExpire - nowTime) > forceTime))) {
        // 删除过期key
        localStorage.removeItem(key)
        // 删除过期时间
        localStorage.removeItem(key + '_expire')
        return false;
    }

    // 获取数据
    let cacheData = JSON.parse(localStorage.getItem(key));

    if (cacheData === null || cacheData === false) {
        return false;
    }
    // 返回数据
    return cacheData;
}

export default {
    cacheSet,
    cacheGet
}
